import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { HelpersService } from '../helpers/helpers.service';
import { GamesQueryService } from '../query/gamesQuery.service';
import { QueryService } from '../query/query.service';
import { selectConfig } from '../../store/config/selector/config.selectors';
import { Store } from '@ngrx/store';
import { FilterActions } from '../../store/filters/actions/filter.actions';
import { NavigationService } from '../navigation/navigation.service';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { FavoriteResponse, Game } from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  url: string = '';
  testUrl: string = 'https://test.welle.games/graphql';
  urlProd: string = 'https://welle.games/graphql';
  lang: string;
  config$: Observable<any> = this.store.select(selectConfig);

  constructor(
    private http: HttpClient,
    private gamesQuery: GamesQueryService,
    private query: QueryService,
    private helper: HelpersService,
    private store: Store,
    private navigation: NavigationService,
    private storage: LocalStorageService
  ) {
    this.lang = this.helper.getCookie('userlang') || 'en';
    this.config$.subscribe(() => {
      this.url = this.urlProd;
    });
  }

  gameImageSetter(imagePath: string, pathToInject: string) {
    const parts = imagePath.split('/');
    parts[parts.length - 2] = pathToInject + '/' + parts[parts.length - 2];
    return parts.join('/').replace('.svg', '.jpg');
  }

  getAllGames(skip: string, take: number) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';

    return this.http.post(this.url, this.gamesQuery.getAllGames(take, skip, this.lang, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        return data?.game;
      })
    );
  }

  findGameCategory(game: any) {
    const categorieToFind: Array<string> = ['New', 'Hot', 'Bonusrestricted', 'Savingsgames', 'spingifts'];
    return game.map((game: any) => {
      categorieToFind.map((category: string) => {
        game[`is${category}`] = game?.gameCategories?.reduce(
          (obj: any, item: any) => ((obj[item.slug] = true), obj),
          {}
        )[`${category.toLowerCase()}`];
      });
      return game;
    });
  }

  getGameByCategory(take: number, skip: string, slug: string) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.gamesQuery.getGameBySlugQuery(slug, take, skip, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        data.gamesByCategory = this.helper.changeGamesUrl(data.gamesByCategory);
        return data.gamesByCategory;
      })
    );
  }
  getGamesByMerchant(take: number, skip: string, merchantId: any) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';

    return this.http.post(this.url, this.query.getGamesByMerchantId(take, skip, merchantId, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        data.game = this.helper.changeGamesUrl(data.game);
        return data.game;
      })
    );
  }
  getAllMerchants() {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.getAllMerchants(country)).pipe(
      map((data: any) => {
        const array = [];
        for (const item in data.merchant) {
          array.push(data.merchant[item]);
        }
        return array;
      })
    );
  }
  getGamesBySlugAndFilter(take: number, skip: string, filter: any, slug: string) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.getGamesBySlugAndFilter(take, skip, slug, filter, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((games: any) => {
        games.game = this.helper.changeGamesUrl(games.game);
        return games.game;
      })
    );
  }
  getGamesByName(take: number, skip: string, value: string) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.searchGameByNameQuery(take, skip, value, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        data.game = this.helper.changeGamesUrl(data.game);
        return data.game;
      })
    );
  }
  getGamesByNameAndFilter(take: number, skip: string, search: string, filter: any) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.getGamesByFilterAndName(take, skip, filter, search, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        data.game = this.helper.changeGamesUrl(data.game);
        return data;
      })
    );
  }
  getGamesByNameAndSlug(take: number, skip: string, search: string, slug: string) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.getGamesBySlugAndName(take, skip, slug, search, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        data.game = this.helper.changeGamesUrl(data.game);
        return data.game;
      })
    );
  }
  getAllCategoryList(lang: any) {
    return this.http.post(this.url, this.query.getAllSlugQuery(lang)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        const result: any = [];
        data.category.map((category: any) => {
          category.categoryTags.forEach((tag: any) => {
            if (tag?.name.includes('main')) {
              result.push(category);
            }
          });
        });
        return result;
      })
    );
  }
  getAllFilterGames(take: number, skip: string, value: string, filter: any, slug: string) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.getGamesByAllFilters(take, skip, slug, filter, country, value)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        data.game = this.helper.changeGamesUrl(data.game);
        return data;
      })
    );
  }
  findGameByIdS(take: number, skip: string, arrayIds: any) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.getGameByIdsQuery(take, skip, arrayIds, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        data.game = this.helper.changeGamesUrl(data.game);
        return data.game;
      })
    );
  }
  findGameByIdSAndFilter(take: number, skip: string, arrayIds: any, filter: any) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.getGameByIdsQueryAndFilter(take, skip, arrayIds, filter, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        data.game = this.helper.changeGamesUrl(data.game);
        return data.game;
      })
    );
  }
  findGameByIdSAndSearch(take: number, skip: string, arrayIds: any, search: string) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.getGameByIdsQueryAndSearch(take, skip, arrayIds, search, country)).pipe(
      catchError((error: Error): any => {
        return throwError(() => error);
      }),
      map((data: any) => {
        data.game = this.helper.changeGamesUrl(data.game);
        return data.game;
      })
    );
  }
  findGameByIdSAndAllFilter(take: number, skip: string, arrayIds: any, search: string, filter: any) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http
      .post(this.url, this.query.getGameByIdsQueryAndAllFilter(take, skip, arrayIds, search, filter, country))
      .pipe(
        catchError((error: Error): any => {
          return throwError(() => error);
        }),
        map((data: any) => {
          data.game = this.helper.changeGamesUrl(data.game);
          return data.game;
        })
      );
  }
  getGamesFromFavoriteList() {
    return this.http.get(`/favorites`);
  }
  findGameById(id: string) {
    const country: string =
      this.storage.getSessionStorage('countryFromProfile') || this.storage.getSessionStorage('country') || '';
    return this.http.post(this.url, this.query.getGameByIdQuery(id, country)).pipe(
      catchError((error: Error): any => {
        throwError(() => error);
      })
    );
  }

  getGamesID(res: any) {
    let gamesID: string = '';
    res.forEach((element: any) => {
      gamesID = gamesID + ',' + element.game_id;
    });
    return gamesID;
  }
  setGameToFavoriteList(id: string, tableID?: string) {
    this.http
      .post<FavoriteResponse>(`/favorites/${tableID !== undefined ? tableID : id}`, {
        ID: id,
      })
      .subscribe((res) => {
        let favoriteGames: string[] = this.storage.getItemFromLocalStorage('favGames') || [];
        (tableID !== undefined && !favoriteGames.includes(id) && res?.favorite === 1) ||
        (!favoriteGames.includes(id) && res?.favorite === 1)
          ? favoriteGames.push(res?.game_id.toString()) && this.incrementGameLike(id)
          : (favoriteGames = favoriteGames.filter((gameId) => gameId !== res?.game_id.toString())) &&
            this.decrementGameLike(id);
        this.storage.setLocalStorage('favGames', JSON.stringify(favoriteGames));
      });
  }

  incrementGameLike(game_id: string) {
    this.http
      .post(`https://welle.games/game-likes/increment/${game_id}`, {
        gameId: Number(game_id),
      })
      .subscribe(() => {});
  }

  decrementGameLike(game_id: string) {
    this.http
      .post(`https://welle.games/game-likes/decrement/${game_id}`, {
        gameId: Number(game_id),
      })
      .subscribe(() => {});
  }

  updateLikes(incrementLike: boolean, game: Game) {
    if (!game.Likes) {
      game.Likes = { likes: 0 };
    }

    if (incrementLike) {
      ++game.Likes.likes;
    } else if (game.Likes.likes > 0) {
      --game.Likes.likes;
    }
  }

  navigateToAllGames() {
    this.store.dispatch(
      FilterActions['[SetFilter]SetFilterCategorySuccess']({
        data: 'all',
      })
    );
    this.navigation.navigateTo(`/games/all`);
  }
  getCategoriesIds(slug: string) {
    return this.http.post(this.urlProd, this.query.getGameIdsByCategory(slug));
  }

  initFilterFromStorageToStore() {
    const loadFilterFromStorage = (storageKey: string, action: any) => {
      const savedData = this.storage.getSessionStorage(storageKey);
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        this.store.dispatch(action(parsedData));
      }
    };
    loadFilterFromStorage('filter', FilterActions['[SetFilter]SetFilterProvidersSuccess']);
    loadFilterFromStorage('search', FilterActions['[SetFilter]SetFilterSearchSuccess']);
  }
}
